import React from 'react';
import ServiceTemplate from './OurServices/ServiceTemplate';
import Img1 from '../Assets/Service/Group 845.png';
import Img2 from '../Assets/Service/Group 1021.png';
import Logo1 from '../Assets/Service/image 6.png';
// import Logo2 from '../Assets/Service/image 3.png'
import YoutubeThumbnail from '../Assets/Service/Rectangle 31.png';

const LandTransportation = () => {
  const data = {
    title: 'Land Transportation',
    description:
      'We handle all types of shipments including sensitive by time, hazardous nature, abnormal size and perishables with utmost care and speed to & from world wide destinations.',
    portraitImage: Img1,
    landscapeImage: Img2,
    landscapeImageDescription: (
      <>
        Reliable Land Freight Solutions: Seamless Transportation Across Borders{' '}
        <br />
        When it comes to land transportation, EFS LOGISTICS is your trusted
        partner. We specialize in handling all types of shipments, including
        time-sensitive, hazardous, oversized, and perishable goods. With our
        commitment to care and speed, we ensure that your cargo reaches its
        destination with utmost efficiency. <br />
        <br />
        As a licensed transport company with our own fleet of trailer heads in
        Saudi Arabia, we offer comprehensive trucking services for both domestic
        and cross-country transport. Our experienced drivers and well-maintained
        vehicles guarantee the safe and timely delivery of your goods. <br />
        <br />
        What sets us apart is our TIR approval, which allows us to handle
        sea-land and land-sea transit movements between GCC/Middle East and
        countries worldwide through Saudi seaports. This solution is ideal for
        customers who require faster transit times between the GCC/Middle East
        and global destinations. <br />
        <br />
        Rest assured, your cargo is in capable hands with EFS LOGISTICS. We
        prioritize the security and integrity of your shipments, providing
        reliable land transportation services that meet your specific
        requirements. <br />
        <br />
        Choose us as your preferred partner for seamless and efficient land
        transportation solutions. Contact us today to discuss your cargo needs
        and experience our exceptional service firsthand.
      </>
    ),
    logos: [Logo1],
    youtubeLink: 'https://linktw.in/rHzJZa',
    youtubeThumbnailImage: YoutubeThumbnail,
    buttonText: 'EFS Land Transportation Services',
  };
  return (
    <ServiceTemplate
      title={data.title}
      description={data.description}
      portraitImage={data.portraitImage}
      landscapeImage={data.landscapeImage}
      landscapeImageDescription={data.landscapeImageDescription}
      logos={data.logos}
      youtubeLink={data.youtubeLink}
      youtubeThumbnailImage={data.youtubeThumbnailImage}
      buttonText={data.buttonText}
    />
  );
};

export function Head() {
  return <title>Land Transportation | EFS Logistics KSA</title>;
}
export default LandTransportation;
